import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="footer py-4">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 text-lg-left">Copyright © NFT Creation Agency 2021</div>
                        <div className="col-lg-4 my-3 my-lg-0">
                            <a className="btn btn-dark btn-social mx-2" href="https://t.me/nftcreationagency"><i className="fab fa-telegram"></i></a>
                        </div>
                        <div className="col-lg-4 text-lg-right">
                            <a className="mr-3" href="/privacy">Privacy Policy</a>
                            <a href="/terms">Terms of Use</a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;