import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { PopupButton } from '@typeform/embed-react';
import { HashLink } from 'react-router-hash-link';

class NavBar extends Component {

  render() {
    return (
      <div >
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
          <div className="container">
            <Link className="navbar-brand js-scroll-trigger" to="#page-top"><img src="assets/img/logo.png" alt="NFT Creation Agency" /></Link>
            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              Menu
                        <i className="fas fa-bars ml-1"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav text-uppercase ml-auto">
                <li className="nav-item"><Link className="nav-link js-scroll-trigger" to="/">Home</Link></li>
                <li className="nav-item"><HashLink className="nav-link js-scroll-trigger" to="/#services">Services</HashLink></li>
                <li className="nav-item"><Link className="nav-link js-scroll-trigger" to="/privacy">Privacy</Link></li>
                <li className="nav-item"><Link className="nav-link js-scroll-trigger" to="/terms">Terms</Link></li>
              </ul>
              <div className="text-center">
                <PopupButton id="V3fmr45r" style={{ fontSize: 20 }} className="my-button">
                  Start My Project
                </PopupButton>
              </div>
            </div>
          </div>
        </nav>
        {this.props.children}
      </div>
    );
  }
}

export default NavBar;
