import React, { Component } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import roadmap from '../assets/img/about/roadmap.svg';
import SingleService from './SingleService';

const serv = [
    {icon:"fas fa-user-astronaut", title:"Designing an NFT", details:"Base Characters + Traits & Attributes"},
    {icon:"fab fa-galactic-senate", title:"Creating a Pixel art", details:"generator software in Python"},
    {icon:"fa-robot", title:"Creating", details:"an NFT 10K/20K NFT Collection"}
]

class Home extends Component {

    render() {
        return (
            <div >
                <section className="page-section" id="services">
                    <div className="container">
                        <div className="text-center">
                            <text style={{fontWeight: 'bold', color: 'white', fontSize: '30px'}}>WE HELP YOU STEP BY STEP INTO CREATING</text><br/>
                            <text style={{fontWeight: 'bold', color: 'red', fontSize:'20px'}}>YOUR OWN 10K/20K NFT COLLECTION</text><br/><br/><br/>
                        </div>
                        <div className="row text-center">
                            {serv.map((service, index) => {
                                return <SingleService {...service} key={index} />
                            })}
                        </div>
                    </div>
                </section>
            </div>
            
        );
    }
}

export default Home;
