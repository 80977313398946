import React, { Component } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import MovingText from 'react-moving-text';
import { PopupButton } from '@typeform/embed-react';
import ProjectCard from "../common/ProjectCard";
import tenKNFT from '../assets/img/about/10KNFT.png';
import cpunks from '../assets/img/about/cpunks.png';
import roadmap from '../assets/img/about/roadmap.svg';
import art from '../assets/img/about/art.png';
import cryptoart from '../assets/img/about/cryptoart.png';
import pixelartcollection from '../assets/img/about/pixelartcollection.png';
import cryptopunks from '../assets/img/about/cryptopunks.png';
import doyouwant from '../assets/img/about/doyouwant.png';
import keyareas from '../assets/img/about/keyareas.png';
import telegram from '../assets/img/about/telegram.svg';
import Service from '../common/Service';
import Header from '../common/Header';

class Home extends Component {

    render() {
        
        return (
            <Container >
                <Row style={{ justifyContent: "center" }}><br/><br/><br/>
                    <ProjectCard 
                        imgPath={tenKNFT}
                    />
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <MovingText
                        type="slideInFromLeft"
                        duration="2000ms"
                        delay="20s"
                        direction="normal"
                        timing="ease"
                        iteration="2"
                        fillMode="none">
                        <text style={{fontWeight: 'bold', color: 'white', fontSize: '20px'}}>NFT Creation Agency helps you design, create, render,  and list your collection whether it be 10k or 100,000 pcs.</text>
                    </MovingText>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <ProjectCard 
                        imgPath={cpunks}
                    />
                </Row>

                <Service />

                <Row style={{ justifyContent: "center" }}>
                    <text style={{fontWeight: 'bold', color: 'white', fontSize: '30px'}}>How It </text>&nbsp;&nbsp; <text style={{fontWeight: 'bold', color: 'red', fontSize: '30px'}}> Works</text>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <ProjectCard
                        imgPath={roadmap}
                    />
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <div className="text-center">
                        <text style={{fontWeight: 'bold', color: 'white', fontSize: '50px', align: 'center'}}>Take The Leap Towards Your</text>
                    </div>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <div className="text-center">
                        <text style={{fontWeight: 'bold', color: 'red', fontSize: '50px', align: 'center'}}>NFT Business.</text>
                    </div>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <Header
                        buttonText="Enroll Now"
                        link="/contact"
                        showbutton={true}
                    />                    
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <ProjectCard 
                        imgPath={art}
                    />
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <ProjectCard 
                        imgPath={cryptoart}
                    />
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <ProjectCard 
                        imgPath={pixelartcollection}
                    />
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <ProjectCard 
                        imgPath={cryptopunks}
                    />
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <ProjectCard 
                        imgPath={doyouwant}
                    />
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <Col md={10}>
                    <div className="text-center">
                        <text style={{color: 'white', fontSize: '20px', align: 'center'}}>
                        The NFT Creation Agency team allows you to bring your further ideas and connections
                         to life by dynamically filtering and sorting your entire collection in one visually 
                         engaging experience. We change the way you use attribute data, revealing trends that 
                         would otherwise be invisible. Get your own 10k generator.</text>
                    </div>
                    </Col>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <ProjectCard 
                        imgPath={keyareas}
                    />
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <Col md={6}>
                        <div className="text-center">
                            <text style={{fontWeight: 'bold', color: 'white', fontSize: '50px', align: 'center'}}>
                            Estimate Your Project</text>
                        </div>
                    </Col>
                </Row>

                <Row style={{ justifyContent: 'center'}}>
                    <Col md={6} style={{justifyContent: 'center'}}>
                        <div className="text-center" style={{ color: 'blue', padding: 10 }}>
                            <text style={{justifyContent: 'center', color: 'red', fontSize: '20px'}}>
                            Get free consultation and build your blockchain project with our highly qualified team!</text>
                        </div>
                    </Col>
                    <br/><br/><br/><br/>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <div className="text-center">
                        <a href="https://t.me/nftcreationagency" target='_blank' rel="noreferrer"><img src={telegram} height={75} width={75} alt=""/></a>
                        <br/><br/>
                    </div>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <div className="text-center">
                        <text style={{justifyContent: 'center', color: 'white'}}>Start a chat with us or click below to submit a form</text><br/><br/>
                    </div>
                </Row>

                <Row style={{ justifyContent: "center" }}>
                    <div className="text-center">
                        <PopupButton id="V3fmr45r" style={{ fontSize: 20 }} className="my-button">
                            Submit a Form
                        </PopupButton>
                        <br/><br/><br/><br/><br/>
                    </div>
                </Row>

            </Container>
        );
    }
}

export default Home;