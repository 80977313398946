import React, { Component } from 'react'; 

class Home extends Component {


    render() {
        return (
            <div>
                <section className="page-section" id="about">
                    <div className="container">
                        <div className="text-left">
                            <h4 className="section-heading text-uppercase">Terms of Service</h4>
                            <h3 className="section-subheading text-muted">Thank you for visiting NFTCreationAgency!<br/><br/>
                            <text style={{fontWeight: 'bold', color: 'red'}}>Welcome to NFTCreationAgency!</text></h3>
                            <h3 className="section-subheading text-muted">These are the terms and conditions that define the rules and regulations to use the NFTCreationAgency website:<br/>
                            <a href="https://nftcreationgency.com"><text style={{color: 'red'}}>https://nftcreationagency.com</text></a><br/><br/>
                            This website is the property of NFTCreationAgency, developed by the NFTCreationAgency development team.</h3>
                        </div>
                        <div className='text-left'>
                            <h4 className='section-heading text-muted'>Acceptance of the terms</h4>
                            <h3 className='section-subheading text-muted'>You must be of legal age to abide by the agreement in order to accept the terms. By accessing this website we assume that you hereby accept the terms and conditions of this website. If you do not agree to the General Terms and conditions, do not continue to use any of our services of NFTCreationAgency.com You may accept the Terms or abide by the general agreement.<br/><br/>
                            NFTCreationAgency referred collectively to “We” or “Us” or” Ourselves” or “Our” We refer to users of the website as “You” or “Users” or “Client”. You hereby bound to these terms.<br/><br/>
                            Any of the above terminologies in singular plural or he or she are interchangeable and they refer to the above terminologies.<br/></h3>
                        </div>

                        <div className='text-left'>
                            <h2 className='section-heading text-muted'>General Terms</h2>
                            <h4 className='section-heading text-muted'>Severability</h4>
                            <h3 className='section-subheading text-muted'>Each paragraph of these terms and conditions has separate functions. If any authority or court decides that any of the terms are against the law, the remaining part of the terms and conditions remain the same in full force and effect.<br/></h3>
                            <h4 className='section-heading text-muted'>Notification</h4>
                            <h3 className='section-subheading text-muted'>We may send notice to you by email or post regarding the service and the website or it may be sent by any other mode. Such notices will be considered to be effective from the time of delivery or dispatch. If you send the notice, it will be considered effective when it is received by us.<br/></h3>
                            <h4 className='section-heading text-muted'>Modification of terms</h4>
                            <h3 className='section-subheading text-muted'>Respect for the privacy and security of data you store with NFTCreationAgency informs our approach to complying with We may or may not change the terms and conditions from time to time. You acknowledge and agree to a modification of terms. If you do not agree to the modification of terms, kindly do not access or continue to use our service. Kindly check all the updates on this page periodically. We comply with the applicable legal agreements to provide or send you the notice of updates or changes to these terms and conditions. We assume your continued use as your acceptance of the changes in the terms and conditions.<br/><br/>
                                We will not disclose or provide any personal information to third-party sources without review from our legal case team and/or prior consent from the user.<br/></h3>
                            <h4 className='section-heading text-muted'>Cookies</h4>
                            <h3 className='section-subheading text-muted'>We make use of cookies and by accessing NFTCreationAgency, we assume that you agreed to make use of the cookies with the NFTCreationAgency privacy policy.<br/><br/>
                                Most of the websites use cookies to collect user details in a temporary file to easily access the website for the next visit. It is implemented to make users comfortable using our website and to enable the proper functioning of certain areas.<br/></h3>
                            <h4 className='section-heading text-muted'>License</h4>
                            <h3 className='section-subheading text-muted'>As a user of this website, you are allowed to have non-exclusive, non-transferable, limited access to use the website.<br/><br/>
                                NFTCreationAgency possesses the right to cancel the license at any time for any valid reason. You may access this website with the following restrictions:<br/><br/>
                                •   You may use the website on your own computer for your personal and non-commercial use.<br/>
                                •   You are not allowed to republish any of the content, any part of the website, service/contest in any form<br/>
                                •   Not allowed to copy, sell, sub-license, or rent any part of the NFTCreationAgency.<br/>
                                •   You must not redistribute the content.<br/>
                                •   You are not allowed to use any device, software to interfere or interrupt or attempt to hinder the proper workflow or functionality of the website.<br/><br/>
                                Cyptosoftwares offers the opportunity to exchange your views in a certain part of the websites. Comments posted on this website reflect the views and opinions of the person who posted the comments and in no way, it reflects the views and opinions of NFTCreationAgency. NFTCreationAgency may not be responsible or liable for the comments posted on the website or not responsible for any liability, damage, or expense caused as a result of any comment on the website.<br/><br/>
                                NFTCreationAgency reserves all the rights to monitor the website and removes any unwanted comments which are considered to be inappropriate, offensive, or violate any rules or regulations of these terms and conditions of NFTCreationAgency.<br/></h3>                        
                            <h2 className='section-heading text-muted'>Content service and ownership</h2>
                            <h3 className='section-subheading text-muted'>
                            The website may contain the content created by the NFTCreationAgency or any of our users. The content, graphics, compilation, digital conversation, design, contests, services, and all intellectual property rights including logos, names, related names, and symbols (referred to as “Intellectual Property”) are protected under copyright, trademark law, industrial property rights. Copyright infringement is strictly prohibited.<br/><br/>
                            If any of the above terms are not followed, accepted violates the terms and conditions as per the copyright law, trademark law and you abide by the legal liability for the same.<br/></h3>
                            <h2 className='section-heading text-muted'>Hyperlinking to the website</h2>
                            <h3 className='section-subheading text-muted'>Below organization may hold the rights to link our website without any prior approval.<br/></h3>
                            <h4 className='section-heading text-muted'>Government agencies</h4>
                            <h3 className='section-subheading text-muted'>Unless authorized by NFTCreationAgency, no one shall hyperlink the website or any part of the website (which may include the following parts but not limited to: logos, content, copyright material, branding) to their own website. Framing the website/ referencing the URL (Uniform Resource Locator) without any written permission or prior approval is strictly prohibited.<br/><br/>
                                We may approve the link request of the organization if we find that the following conditions are met:<br/><br/>
                                •   The link should not look unfavorable to us or any of our accredited business<br/>
                                •   The organization should not have a negative impact on our business<br/>
                                •   The benefit to us with respect to the hyperlink<br/>
                                •   The presence of the link in the general information<br/><br/></h3>
                            <h2 className='section-heading text-muted'>Iframes</h2>
                            <h3 className='section-subheading text-muted'>You are not allowed to create any frames that affect or alter our visual representation or design or the appearance of our website without any prior written permission or approval.</h3>
                            <h2 className='section-heading text-muted'>Content liability</h2>
                            <h3 className='section-subheading text-muted'>We shall not be liable for any content that appears on your website. You hereby acknowledge and agree to protect us from all claims that may arise on your website. No link should be allowed on any website that may be considered as criminal, or copyright-infringing, or violating the terms and conditions of third party rights.<br/><br/>
                                You specifically need to agree or cooperate with the website to cease any such activities against hyperlinking. You also acknowledge that you shall be liable for any such legal liabilities, damages, expenses associated therewith.</h3>
                            <h2 className='section-heading text-muted'>Rights reserved</h2>
                            <h3 className='section-subheading text-muted'>We reserve the right to remove any or all the links to our website. You accept to remove immediately as it is requested. We may change, amend the terms and conditions related to hyperlinking policy. We assume that by continuously linking to our website, you accept or agree to the updated terms and conditions of the hyperlinking policy.</h3>
                            <h2 className='section-heading text-muted'>Link removal request from our website</h2>
                            <h3 className='section-subheading text-muted'>If you find any link is not appropriate or offensive, you are allowed to inform or contact us for the removal request. We will consider your request and shall proceed only after examining the link and we are not obligated to respond to you immediately or directly.</h3>
                            <h2 className='section-heading text-muted'>Disclaimer</h2>
                            <h3 className='section-subheading text-muted'>As per the applicable law, we exclude all conditions, warranties, representations relating to our website to the maximum extent.<br/><br/>
                                <strong>The following will not:</strong><br/>
                                •   Exclude or avoid or limit your or our liabilities for death or any personal injury that occurred<br/>
                                •   Exclude or avoid or limit your or our liabilities for fraudulent activities<br/>
                                •   Exclude or avoid or limit your or our liabilities that may or may not fall under applicable law</h3>
                            <h2 className='section-heading text-muted'>Disclaimer for downloads</h2>
                            <h3 className='section-subheading text-muted'>Users or Visitors download any information at their own risk. NFTCreationAgency not liable or no warranty for such downloads are free from viruses, worms, including but not limited to corrupting computer codes As far as the website, services, information are provided to the users for free of cost, we will not be responsible for any natural damage or loss.</h3>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Home;