import React, { Component } from 'react';
import { Row} from "react-bootstrap";
import { PopupButton } from '@typeform/embed-react';

class Contact extends Component {
    render() {
        return (
            <div>
                <section className="page-section" id="contact">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="section-heading text-uppercase">Contact Us</h2>
                            <h3 className="section-subheading text-muted">We are Happy to Help You.</h3>
                            <h4 className='section-subheading text-muted'>Please click below to submit a form</h4><br/><br/><br/>
                        </div>
                        <Row style={{ justifyContent: "center" }}>
                    <div className="text-center">
                        <PopupButton id="V3fmr45r" style={{ fontSize: 20 }} className="my-button">
                            Submit a Form
                        </PopupButton>
                        <br/><br/><br/><br/><br/>
                    </div>
                </Row>                        
                    </div>
                </section>
            </div>
        );
    }
}

export default Contact;